<template>
    <div class="main main-scroll btm-menu">
        <div tabindex="-1" class="main-sidebar">
            <div class="main-sidebar__head">
                <h4 class="title">{{$t('angebote.title')}}</h4>
                <p>{{$t('angebote.info')}}</p>
            </div>

            <div class="main-sidebar__content none" v-if="!$isMobileScreen && tempShowHide">
                <keyword-search class="mb20" :initialValue="dataSet.query.keyword" @searchWordChanged="searchWordChanged" ref="searchBox"/>

                <angebote-filter v-if="contentType!==null && sublocalityFilter!==null" :contentType="contentType" :selectedFilters="selectedFilters" :structuredFilters="structuredFilters" :sublocalityFilter="sublocalityFilter" @dateTimeChanged="setDateQuery" @filterChanged="setQueryFilters" @resetAllFilters="resetAllFilters"/>

                <div class="clearfix"></div>

            </div>
        </div>

        <div tabindex="-1" class="main-content" id="main-scroll-container" :style="hasResults">
            <TitleBar :initialContentType="$t('angebote.title')" v-if="contentType!==null && $isMobileScreen" 
            karteLink="/orte" :filters="allFilters" :selectedFilters="selectedFilters" :showTermin="true" :showToggleFilters="true"
            listeLink="/orte?show=liste" :showMapOrList="showMap" :isFixed="true" :contentCount="tempContentCount" 
            :initialKeywordSearchValue="dataSet.query.keyword" @contentFilteringFinished="filteringFinished"
            @resetAllFilters="resetAllFilters" @getNumberOfResults="getNumberOfResults" @searchWordChanged="searchWordChanged" @changedTimeFilters="setDateQuery"/>

            <div class="section">
                <div class="container-fluid" v-if="dataSet.total>0">
                    <div class="row">
                        <div class="col-12">
                            <h5 class="mb20">{{dataSet.total}} {{$t('merkliste.results')}}</h5>
                        </div>
                    </div>
                    <div class="offer-row mt-3 row" v-if="dataSet.data!==null && dataSet.data.length>0">
                        <div class="top"></div>
                        <div class="col-12 col-md-6 col-lgs-6 col-lg-6 col-xls-6 col-xl-4 d-flex align-items-stretch" v-for="(item, index) in dataSet.data" :key="index">
                            <angebot-card :item="item" :noslider="true"/>
                        </div>
                    </div>
                    <pagination v-if="dataSet.total>12" break-view-link-class="break-style" :page-count="dataSet.meta.last_page" :click-handler="changePage" containerClass="pagination" prev-text="&laquo" next-text="&raquo"/>
                    </div>
                    <div v-else class="search-no-results">
                        <img src="/assets/search-no-result.png" :alt="$t('angebote.noresult')">
                        <h5 class="mt20">{{$t('angebote.noresult')}}</h5>
                    </div>
                </div>
            </div>


        </div>
    </template>

    <script>
        import { getCtype } from '@/api/contentType';
        import Resource from '@/api/resource';
        const contentTypeResource = new Resource('contenttypes');
        import { getSublocalities } from '@/api/system';
        import { getContentsOfType } from '@/api/content';
        import { addZero, getReadableTime, getFilterDate, compareAndSwitchDates, getWeekday, getMonth } from '@/utils/appointment-helpers';
        import { showLoader, hideLoader, getFilterFields, createSelectOptions, getAllowedValuesFromFieldType, getStructuredFilters, getBasicFilters, argsFilterMapToJSMap, isset, changeRouteQuery } from '@/utils/helpers';
        import { screenSizeMixin } from '@/mixins/screenSizeMixin';
        import VueScrollTo from 'vue-scrollto';

        export default {
            name: "Angebote",
            mixins: [screenSizeMixin],
            components: {
                AngeboteFilter: () => import('@/components/filters/angebote.vue'),
                AngebotCard: () => import('@/components/cards/Angebot.vue'),
                TitleBar: () => import('@/components/controls/TitleBar/TitleBar.vue'),
                WBSelectField: () => import('@/components/controls/WBSelectField.vue'),
                DependencyFilterStructure: () => import('@/components/controls/DependencyFilterStructure.vue'),
                MainCategorySelect: () => import('@/components/controls/MainCategorySelect.vue'),
                MainCategorySelectModal: () => import('@/components/modals/MainCategorySelectModal.vue'),
                KeywordSearch: () => import('@/components/controls/KeywordSearch'),
                TimePicker: () => import('@/components/controls/TimePicker'),
                CustomDatePicker: () => import('@/components/controls/CustomDatePicker'),
                Pagination: () => import('@/components/controls/Pagination'),
                ToggleSwitch: () =>import('@/components/controls/ToggleSwitch')
            },
            data() {
                return {
                    tempShowHide: true,
                    showMainCategorySelectModal: false,
                    currentContentTypeId: this.$offerId,
                    tempContentCount: 0,
                    contentType: null,
                    sublocalities: null,
                    isMobileView: false,
                    dataSet: {
                      total: 0,
                      data: [],
                      meta:null,
                      query: {
                        page:1,
                        type: "view",
                        limit: 12,
                        keyword: '',
                        filters: null,
                        selectedEndDate: null,
                        selectedStartDate: null,
                        selectedStartTime: null,
                        selectedEndTime: null,
                        appId: this.$appId,
                        selectedEndTime: null,
                        selectedContentTypeId: this.currentContentTypeId,
                        view_status: 1
                    },
                },
                selectedCategory: null,
                selectedFilters: new Map(),
                expandFilter: false,
            }
        },
        created(){
            this.getOfferSettings();
            var filters = argsFilterMapToJSMap(this.uriFilters);
            if(isset(filters)){
                this.filteringFinished(filters);
                this.showMainCategorySelectModal = false;
            }
            else{
               this.showMainCategorySelectModal = true;   
           }
           this.getContents();
       },
       watch: {
        $screenWidth(newWidth) {
            if(newWidth<=991){
                this.isMobileView = true;
            }
            else{
                this.isMobileView = false;
            }
        },
        isMobileView(newValue,oldValue){
            /*resetting the filters if the view changes from mobile to desktop*/
            if(newValue!==oldValue){
                this.selectedFilters=new Map();
                this.resetAllFilters();
            }
        }
    },
    computed: {
        hasResults(){
            if(this.dataSet.data.length <= 0){
                return "display: flex;justify-content: center;align-items: center;"
            }
            return '';
        },
        uriFilters(){
            return this.$route.query.filters;
        },
        showMap() {
            if(this.$isDesktopScreen){
                return true;
            }

            if(Object.prototype.hasOwnProperty.call(this.$route.query,'show')){
                return false;
            }
            return true;
        },
        structuredFilters(){
           return getStructuredFilters(this.contentType);
       },
       allFilters(){
           return this.structuredFilters.concat(this.sublocalityFilter); 
       },
       sublocalityFilter(){
        if(this.sublocalities!==null){
           var bla = {};
           bla.field_type_id="location";
           bla.label = this.$t('search.districts');
           bla.options = []
           for(var i = 0; i < this.sublocalities.length; i++){
            bla.options.push({label:this.sublocalities[i].sublocality,value:this.sublocalities[i].sublocality});
        }
        return bla;
    }
    return null;
},

},
methods: {
 showLoader, 
 hideLoader,
 getSublocalities, 
 createSelectOptions,
 getAllowedValuesFromFieldType,
 changePage(page){
    this.dataSet.query.page = page;
    this.$scrollTo({container: '.main-content .section'});
    this.getContents();
},
setDateQuery(dateTimeSelection){
    this.dataSet.query.page = 1;
    this.dataSet.query.selectedStartDate = getFilterDate(dateTimeSelection.selectedStartDate);
    this.dataSet.query.selectedEndDate = getFilterDate(dateTimeSelection.selectedEndDate);
    this.dataSet.query.selectedStartTime = getReadableTime(dateTimeSelection.selectedStartTime);
    this.dataSet.query.selectedEndTime = getReadableTime(dateTimeSelection.selectedEndTime);
    this.getContents();
},
filterChanged(fieldTypeId,values){
    this.tempShowHide = false;
    this.expandFilter = false;
    this.dataSet.query.page = 1;
    this.selectedFilters.set(fieldTypeId, values);
    this.dataSet.query.filters = JSON.stringify(Array.from(this.selectedFilters.entries()));
    this.getContents();
    this.expandFilter = true;
},
getOfferSettings(val){
    this.dataSet.query.selectedContentTypeId = this.currentContentTypeId;
    this.getContentType();
    this.getTheSublocalities();
},
searchWordChanged(word){
    this.dataSet.query.keyword = word;
    this.dataSet.query.page = 1;
    this.getContents();
},
getNumberOfResults(map){
    /*tempFilters will contain all the filters set for now but only for the purpose of calculating the number of items found for these filtersettings*/
    var tempFilters = new Map([...this.selectedFilters,...map]);
    /*#todo: we'll have to start a call to the backend to find out how many values there are for this selection*/
    this.getNumberOfContentsMatchingTheFilter(tempFilters);
},
filteringFinished(map){
    /*get's invoked when the user closes the more-filters-modal with the show results-btn this also means, that we'll have to start searching now*/
    this.expandFilter = false;
    this.selectedFilters = map;
    this.dataSet.query.page = 1;
    this.dataSet.query.filters = JSON.stringify(Array.from(this.selectedFilters.entries()));
    this.getContents();
    this.expandFilter = true;
},
setQueryFilters(){
    this.dataSet.query.page = 1;
    this.dataSet.query.filters = JSON.stringify(Array.from(this.selectedFilters.entries()));
    this.getContents();
},
resetAllFilters(){
    this.tempShowHide = false;
    this.dataSet.query.page = 1;
    this.selectedFilters = new Map;
    this.dataSet.query.selectedStartDate = null;
    this.dataSet.query.selectedEndDate = null;
    this.dataSet.query.selectedStartTime = null;
    this.dataSet.query.selectedEndTime = null;
    this.filteringFinished(this.selectedFilters);
},
getContentType() {
    this.contentType = null;
    var id = this.currentContentTypeId;
    this.loader = this.showLoader(this.loader);
    /*overwrite the existing filters*/
    this.selectedFilters = new Map();
    getCtype(id,this.$store.getters.getLanguage).then(response => {
        this.contentType = response.data;
    })
    .finally(() => {
        this.loader = this.hideLoader(this.loader);
    });
},
async getTheSublocalities() {
    var id = this.currentContentTypeId;
    getSublocalities(this.$city,null, id).then(response => {
        this.sublocalities = response;
    });
},
getContents() {
    /*test to see if that influences the back-button*/
    /*könnte man auch für alle Filter machen - dann müsste man da aber wieder eine Funktion schreiben die das Dingens zurück verwandelt in ein Objekt das aussieht wie der Query ...
    changeRouteQuery(this.$route, this.dataSet.query.filters);*/
    history.pushState({},null,this.$route.path + '?filters=' + encodeURIComponent(this.dataSet.query.filters));

    this.dataSet.query.type="view";
    var contentLoader = this.showLoader(contentLoader);
    const { limit, page } = this.dataSet.query;
    getContentsOfType(this.currentContentTypeId,this.dataSet.query,this.$store.getters.getLanguage).then(response => {
        const { data, meta } = response;
        this.dataSet.data = data;
        this.dataSet.data.forEach((element, index) => {
          element['index'] = (page - 1) * limit + index + 1;
      });
        this.dataSet.meta = meta;
        this.dataSet.total = meta.total;
        this.tempContentCount = meta.total;
    })
    .finally(() => {
        contentLoader = this.hideLoader(contentLoader);
        this.tempShowHide = true;
        document.getElementById('main-scroll-container').scroll({top:0,behavior:'smooth'});
    });
},
getNumberOfContentsMatchingTheFilter(tempFilters){
    this.dataSet.query.type="count";
    this.dataSet.query.filters = JSON.stringify(Array.from(tempFilters.entries()));
    var contentLoader = this.showLoader(contentLoader);
    getContentsOfType(this.currentContentTypeId,this.dataSet.query, this.$store.getters.getLanguage).then(response => {
        this.tempContentCount = response;
    })
    .finally(() => {
        contentLoader = this.hideLoader(contentLoader);
    });
},
}
};
</script>
<style lang="scss" scoped>
    @import '@/scss/_variables.scss';

    .search-no-results{
        margin-top: 50px;
        width: 100%; 
        text-align: center;

        img{
            width: 150px;
        }  
    }

    .main-sidebar__content{
      min-height: 930px;
  }

  .offer-row{
    .col-xls-6{            
        @media(max-width: 1450px) {
            flex: 0 0 50%;
            max-width: 50%;
        }
    }

    .col-lgs-6{            
        @media(max-width: 1150px) {
            flex: 0 0 100%;
            max-width: 100%;
        }
    }

    .col-md-6 {
        @media(max-width: 991px) {
          flex: 0 0 50%;
          max-width: 50%;
      }
  }

  .col-12 {
    @media(max-width: 700px) {
      flex: 0 0 100%;
      max-width: 100%;
  }
}
}

.card {
    margin-bottom: 35px;
}

.filter-header {
    display: flex;
    margin-top: 30px;
    margin-bottom: 15px;

    .reset-btn {
        margin-left: auto;
    }
}

.time-picker-header {
    margin-top: 30px;
    margin-bottom: 15px;
}

body[dir="rtl"] {
    .reset-btn {
        margin-right: auto;
        margin-left: 0;
    }
}
</style>
